import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Button,
  Badge
} from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import LocalizedLink from '../components/LocalizedLink';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import TriDymeIcon from '../media/TriDymeIconV1.png';
import frSvg from '../components/Language/img/fr.svg';
import enSvg from '../components/Language/img/gb.svg';
import usSvg from '../components/Language/img/us.svg';
import Title from '../components/UIComponents/Title';
import SEO from '../components/SEO';
import locales from '../constants/locales';

const {
  REACT_COMPANY_NAME,
} = process.env;

const JobPost = ({ pageContext: { locale }, data }) => {
  const post = data.markdownRemark
  return (
    <Layout locale={locale}>
      <SEO
        title={`TriDyme | ${post.frontmatter.title}`}
        lang={locale}
        url={locales[locale].default
          ? `/${post.fields.slug}`
          : `/${locales[locale].path}${post.fields.slug}`}
        description={post.frontmatter.description}
        author={post.frontmatter.author}
      />
      <Container className="jobPostsContainer">
        <Row>
          <Title
            icon={TriDymeIcon}
            title={post.frontmatter.title}
            description={post.frontmatter.date}
          />
        </Row>

        <Breadcrumb className="breadcrumbBlog">
          <BreadcrumbItem className="breadcrumbItemBlog">
            <LocalizedLink to="/career/">
              Job
            </LocalizedLink>
          </BreadcrumbItem >
          <BreadcrumbItem className="breadcrumbItemBlog">
            {post.frontmatter.title}
          </BreadcrumbItem>
        </Breadcrumb>
        <hr />
        <Row className="main">
          <Col md={12}>
            <div className="contentBlog">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </Col>
        </Row>
        <div className="socialFooter">
          <br />
          <Row className="innerSocialFooter">
            <Col xs={4} className="shareSocial">
              {/* <p>Retrouvez nous sur:</p> */}
            </Col>
            <Col xs={1}>
              <FacebookShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <FacebookIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#10D7EE' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <LinkedinShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <LinkedinIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#10D7EE' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <TwitterShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <TwitterIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#10D7EE' }}
                  />
                }
              />
            </Col>
            <Col xs={1}>
              <EmailShareButton
                url={`https://www.tridyme.com${post.fields.slug}`}
                className="socialIcon"
                children={
                  <EmailIcon
                    size={30}
                    round={true}
                    iconBgStyle={{ fill: '#10D7EE' }}
                  />
                }
              />
            </Col>
            <Col xs={4} />
          </Row>
        </div>
        <div className="footerBlog">
          <hr />
          <Row className="innerFooterBlog">
            <Col md={1} />
            <Col md={11}>
              <h5 className="subtitle">{post.frontmatter.author}</h5>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}


JobPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default JobPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD-MM-YYYY")
        author
        language
        description
        featuredImage {
          publicURL
        }
      }
      fields {
        slug
      }
    }
  }
`
